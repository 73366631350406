.current {
    color: black;
    text-decoration: none;
}

h4 {
    font-size: 12px;
    font-weight: 200;
    line-height: 1.2;
}

.code_blocks {
    background-color: #eee;
    border: 1px solid #999;
    display: block;
    font-family: 'Consolas', sans-serif;
    padding: 5px;
}

.inline_code {
    background-color: #eee;
    border-radius: 3px;
    //font-family: courier, monospace;
    padding: 0 3px;
}

.banner {
    position: relative;
    background: #eb7107 url(/assets/images/bg-tile.png);
    line-height: 2;
    margin: 10px -20px 0px -20px;
    //border-radius: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
}

.banner .blog_name {
    position: relative;
    font-family: 'Days One', sans-serif;
    font-size: 40px;
    font-weight: 900;
    flex-grow: 15;
    padding-left: 10px;
    //background-color: red;
    padding : 0px;
    line-height: 1;
}

// .lets_connect {
//     //background-color: blue;
// }

.socialmedia_container {
    display: flex;
    position: relative;
    align-items: center;
    //background-color: red;
    max-height: 40px;
    margin-top: -15px;
}



.main_links {
    padding-left: 10%;
    padding-right: 5%;
    //background-color: red;
}


.socialmedia_container .twitter_icon {
    margin: 10px 15px 10px 10px;
    position: relative;
    width: 32px;
    height: 32px;
}

.socialmedia_container .gh_icon {
    margin: 10px 15px 10px 10px;
    position: relative;
    width: 32px;
    height: 32px;
}

.socialmedia_container .ln_icon {
    margin: 10px 15px 10px 10px;
    position: relative;
    width: 32px;
    height: 32px;
}

.banner .blog_links {
    position: relative;
    flex-grow: 1;
    margin-right: 15px;
    color: white;
    text-decoration: none;
    align-self: center;
}

.blog_title {
    color : #d9024d;
    //color: #ed4700;
    text-decoration: none;
    font-size: 150%;
    line-height: 1.2;
    font-weight: 400;
   
}

.post_content {
    font-size: 100%;
    word-wrap: break-word;
}

.project_content {
    word-wrap: break-word;
}

.home_recentposts_container {
    margin-left: 10px;
}


.home_recentposts {
    text-decoration: none;
    color : #d9024d;
    margin-left: 5px;
}


.current_not {
    color: white;
    text-decoration: none;
}

.date {
    font-weight: 700;
}

.about_container {
    //position : absolute;
    top : 30%;
    left :50%;
    //transform: translate(-50%,-50%);
    padding: 5px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 4px;
    //box-shadow: 0 5px 15px rgba( 0,0,0,0.5);
    display: flex;
    max-width: 900px;
    margin: auto;
    //background-color: red;
}

.about_container .about_image_box {
    width: 220px;
    flex : 0 0 150px;
    //background-color: green;
    padding: 10px;
}

.about_container .about_image_box img{
    max-width: 85%;
    max-height: 85%;
    border: 1px solid black;
}

.about_container .about_text {
    margin-left: 20px;
    padding-left: 10px;
    max-width: 300;
    margin: auto;
    //background-color: blue;
}

.content {
    font-family: 'Open Sans', sans-serif;
    font-size: 17px;
    max-width: 900px;
    min-height: 700px;
    margin: auto;
    background: white;
    padding: 20px;
    border-radius: 5px;
    padding-bottom: 10.5rem;
}

.default_bkg {
  background:#252525 url(/assets/images/bg-tile.png);
}

.footer {
    font-family: 'Open Sans', sans-serif;
    font-size: 100%;
    bottom: 0;
    max-width: 930px;
    margin: auto;
    margin-top: 3px;
    background: #eb7107 url(/assets/images/bg-tile.png);
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    height: 4rem;
}

#page-container {
    position: relative;
    min-height: 100vh;
}

.project_title{
    color : #d9024d;
    font-size: 200%;
    line-height: 1.2;
    font-weight: 500;
}